import { Box } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../../common/componentes/alert-message/alert-message";
import BackButton from "../../../common/componentes/back-button/back-button";
import BreadcrumbsHandler from "../../../common/componentes/breadcrumbs-handler/breadcrumbs-handler";
import Button from "../../../common/componentes/button/button";
import PageTitle from "../../../common/componentes/page-title/page-title";
import { useAppSelector } from "../../../store/store";
import { actualEvent } from "../../../common/helpers/actual-event";
import withTemplate from "../../../styles/template/template";
import CancelationModal from "../components/cancelation-modal/cancelation-modal";
import TableDetails from "../components/table-details/table-details";
import {
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useDispatchOrderMutation,
  useFinishOrderMutation,
  useGetOrdersQuery,
} from "../slice/orders.api";
import { currentOrderSelector } from "../slice/orders.slice";
import { CancelationCodes } from "../types/orders";
import api from "../../../configs/api";

const breadcrumbsConfig = [
  {
    label: "Pedidos",
    path: "/orders",
  },
  {
    label: "Detalhes do pedido",
  },
];

function OrdersDetails() {
  const navigate = useNavigate();
  const order = useAppSelector(currentOrderSelector);
  const eventType = order.eventType;
  const [isCancelationModalOpen, setIsCancelationModalOpen] = useState(false);

  const [cancelOrderMutation] = useCancelOrderMutation();
  const [confirmOrderMutation] = useConfirmOrderMutation();
  const [dispatchOrderMutation] = useDispatchOrderMutation();
  const [finishOrderMutation] = useFinishOrderMutation();
  const orderRequest = useGetOrdersQuery();

  const openModal = useCallback(() => setIsCancelationModalOpen(true), []);

  const closeModal = useCallback(() => setIsCancelationModalOpen(false), []);

  const handleCancelation = useCallback(
    async (code: CancelationCodes, reason = "Any reason") => {
      closeModal();
      await cancelOrderMutation({ orderId: order.id, code, reason });
      orderRequest.refetch();
      navigate("/orders");
    },
    []
  );

  const handleConfirmation = useCallback(async () => {
    await confirmOrderMutation({
      orderId: order.id,
      displayId: order.displayId,
    });
    orderRequest.refetch();
    navigate("/orders");
  }, []);

  const handleOrderAction = useCallback(async () => {
    if (eventType === "READY_FOR_PICKUP") {
      await dispatchOrderMutation({ orderId: order.id });
      orderRequest.refetch();
      navigate("/orders");
    }

    if (order.eventType === "CONFIRMED") {
      await dispatchOrderMutation({ orderId: order.id });
      orderRequest.refetch();
      navigate("/orders");
    }

    if (eventType === "DISPATCHED") {
      await finishOrderMutation({ orderId: order.id, type: "CONCLUDED" });
      orderRequest.refetch();
      navigate("/orders");
    }
  }, []);

  const onHandlePrint = useCallback(() => {
    window.open("/orders/print");
  }, []);

  return (
    <>
      <Box component="main" p={2} maxWidth={1100}>
        <PageTitle title="Detalhes do pedido" />
        <BreadcrumbsHandler items={breadcrumbsConfig} />

        <Box>
          <Box mt={3}>
            <BackButton onClick={() => navigate(-1)} />
          </Box>
          <Box>
            {eventType === "CREATED" && (
              <AlertMessage
                title="Atenção!"
                type="warning"
                description="Você poderá ver as informações completas do cliente assim que aceitar o pedido."
              />
            )}
            {eventType === "READY_FOR_PICKUP" && (
              <AlertMessage
                title="Atenção!"
                type="warning"
                description="Não esqueça de finalizar o pedido assim que for entregue para o cliente."
              />
            )}
            {eventType === "CANCELLED" && (
              <AlertMessage
                title="Pedido cancelado"
                type="error"
                description="Para acessar os pedidos cancelados, vá na aba Histórico. Em caso de dúvidas, acione o nosso suporte."
              />
            )}

            <TableDetails order={order} />
            {eventType === "CREATED" ? (
              <Box
                justifyContent="flex-end"
                display="flex"
                gridGap={24}
                mt={4}
                mb={4}
              >
                <Button variant="outlined" color="primary" onClick={openModal}>
                  Recusar pedido
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmation}
                >
                  Aceitar pedido
                </Button>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                mt={4}
                mb={4}
                gridGap={24}
              >
                <Button
                  variant="outlined"
                  style={{ borderColor: "#DD3344", color: "#DD3344" }}
                  disabled={eventType === "CANCELLED"}
                  onClick={openModal}
                >
                  Cancelar pedido
                </Button>
                <Box justifyContent="flex-end" display="flex" gridGap={24}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={eventType === "CANCELLED"}
                    onClick={onHandlePrint}
                  >
                    Imprimir vias
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={eventType === "CANCELLED"}
                    onClick={handleOrderAction}
                  >
                    {eventType === "READY_FOR_PICKUP" ||
                    eventType === "DISPATCHED"
                      ? "Finalizar pedido"
                      : "Pedido pronto"}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <CancelationModal
        open={isCancelationModalOpen}
        onClose={closeModal}
        onCancel={handleCancelation}
      />
    </>
  );
}

export default withTemplate(OrdersDetails);
