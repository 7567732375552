import { Box, MenuItem, Select } from "@material-ui/core";

import { useStyles } from "./historic-orders.styles";
import { useState } from "react";

import PageTitle from "../../../common/componentes/page-title/page-title";
import TotalCards from "../components/total-cards/total-cards";
import TableHistoric from "../components/table-historic/table-historic";
import withTemplate from "../../../styles/template/template";
import { useGetOrdersQuery } from "../../orders/slice/orders.api";
import { config } from "../../../configs/config";

function Home() {
  useGetOrdersQuery(null, {
    pollingInterval: config.pollingInterval,
  });

  const classes = useStyles();
  const [filter, setFilter] = useState("TODAY");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box component="main" p={2} width="100%">
      <PageTitle title="Histórico" />
      <Box display="flex" className={classes.container}>
        <Select
          className={classes.selectfilter}
          value={filter}
          label="Dias"
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value="TODAY">Hoje</MenuItem>
          <MenuItem value="LAST_DAY">Dia Anterior</MenuItem>
          <MenuItem value="LAST_7_DAYS">Últimos 7 dias</MenuItem>
          <MenuItem value="LAST_14_DAYS">Últimos 14 dias</MenuItem>
          <MenuItem value="LAST_30_DAYS">Últimos mês</MenuItem>
        </Select>
      </Box>

      <TotalCards days={filter} />
      <TableHistoric days={filter} />
    </Box>
  );
}

export default withTemplate(Home);
